import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

interface Props {
  handleScroll: (direction: number) => void;
}

const ProjectNavigation: React.FC<Props> = ({ handleScroll }) => {
  return (
    <div className="flex space-x-2 items-start">
      <NavigationButton handleScroll={handleScroll} direction={-1} />
      <NavigationButton handleScroll={handleScroll} direction={1} />
    </div>
  );
};

interface NavigationButtonProps extends Props {
  direction: number;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  handleScroll,
  direction,
}) => {
  return (
    <motion.button
      className="border py-1 px-2 lg:py-2 lg:px-4 bg-primary rounded"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => {
        handleScroll(direction);
      }}
    >
      <FontAwesomeIcon
        icon={direction == 1 ? faArrowRight : faArrowLeft}
        color="white"
      />
    </motion.button>
  );
};

export default ProjectNavigation;
