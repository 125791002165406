interface keyboardInput {
  keyCode: number;
  key: string;
}

export const keyboardInputs: { [key: string]: keyboardInput } = {
  ENTER: {
    keyCode: 13,
    key: 'Enter',
  },
  SPACE: {
    keyCode: 32,
    key: ' ',
  },
  ESCAPE: {
    keyCode: 27,
    key: 'Escape',
  },
  LEFTARROW: {
    keyCode: 37,
    key: 'ArrowLeft',
  },
  RIGHTARROW: {
    keyCode: 39,
    key: 'ArrowRight',
  },
};

const defaultKeyboardInputs: keyboardInput[] = [
  keyboardInputs.ENTER,
  keyboardInputs.SPACE,
];

// General key handler for use when a11y requires a key handler for static, non-interactive elements
export const keyHandler = (
  event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent,
  callback: (key: string | number) => void,
  triggerInputs: keyboardInput[] = defaultKeyboardInputs,
  allowRepeat: boolean = true
): void => {
  // eslint-disable-next-line deprecation/deprecation
  const key = event.key || event.keyCode;

  // Check if the event-input is the same as the allowed inputs
  const allowedInput = triggerInputs.some(
    (input) => input.key === key || input.keyCode === key
  );

  const repeatChecked = allowRepeat || !event.repeat;
  const metakeyPressed = event.ctrlKey || event.metaKey;

  if (allowedInput && repeatChecked && !metakeyPressed) {
    // Cancel the default action, if needed
    event.preventDefault();
    callback(key);
  }
};
