import React, { useEffect, useRef } from 'react';
import { keyHandler, keyboardInputs } from '../utils/keyHandler';

const useCarouselScroll = () => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const handleScroll = (direction: number) => {
    const scrollStep = window.screen.width < 768 ? 200 : 316;
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.scrollLeft += direction * scrollStep;
    }
  };

  const handleKeyDown = (key: string | number) => {
    if (
      key === keyboardInputs.LEFTARROW.key ||
      key === keyboardInputs.LEFTARROW.keyCode
    ) {
      handleScroll(-1);
    } else if (
      key === keyboardInputs.RIGHTARROW.key ||
      key === keyboardInputs.RIGHTARROW.keyCode
    ) {
      handleScroll(1);
    }
  };

  const keydownHandler = (e: KeyboardEvent) =>
    keyHandler(
      e,
      (key) => handleKeyDown(key),
      [keyboardInputs.LEFTARROW, keyboardInputs.RIGHTARROW],
      false
    );

  useEffect(() => {
    window.addEventListener('keydown', keydownHandler);
    return () => window.removeEventListener('keydown', keydownHandler);
  }, []);

  return { carouselRef, handleScroll };
};

export default useCarouselScroll;
