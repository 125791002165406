import React from 'react';
import Layout from '../../containers/layout';
import ProjectCard from '../../components/projects/ProjectCard';
import ProjectNavigation from '../../components/projects/ProjectNavigation';
import { graphql } from 'gatsby';
import { ProjectsQuery } from '../../../graphql-types';
import useCarouselScroll from '../../hooks/useCarouselScroll';
interface Props {
  data: ProjectsQuery;
}

const ProjectsPage: React.FC<Props> = ({ data }) => {
  const { carouselRef, handleScroll } = useCarouselScroll();

  return (
    <Layout>
      <div className="lg:mt-32 lg:ml-8 xl:ml-40 sm:mt-12">
        <div className="flex flex-col lg:grid lg:grid-flow-col lg:grid-cols-12 lg:gap-8">
          <div className="mx-4 mb-4 lg:mb-0 lg:col-span-4 xl:col-span-3 space-y-4">
            <h1 className="text-3xl md:text-5xl">Some projects</h1>
            <div className="flex flex-row justify-between items-baseline lg:space-y-4 space-y-0 lg:flex-col">
              <p className="prose md:mr-8">
                These are some of the projects I've worked on. They include some
                hobby projects, some projects from my internships and some from
                volunteer work. If you find any of the projects interesting,
                make sure to click it to read a little further!
              </p>
              <div className="hidden md:block">
                <ProjectNavigation handleScroll={handleScroll} />
              </div>
            </div>
          </div>
          <div className="md:col-span-8 xl:col-span-9 md:gap-4">
            <div
              ref={carouselRef}
              id="carousel"
              style={{
                scrollPaddingLeft: '5px',
                scrollBehavior: 'smooth',
              }}
              className="flex overflow-x-scroll no-scrollbar scroll-snap-x"
            >
              {data.allSanityProject.edges.map((project) => (
                <ProjectCard key={project.node.name} project={project} />
              ))}
            </div>
            <div className="ml-4 mt-2 block md:hidden">
              <ProjectNavigation handleScroll={handleScroll} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Projects {
    allSanityProject(sort: { fields: order, order: ASC }) {
      edges {
        node {
          name
          shortDescription
          technologies {
            technology {
              name
            }
          }
          id
          cardColor {
            hex
          }
          mainImage {
            ...SanityImage
          }
          slug {
            current
          }
        }
      }
    }
  }
`;

export default ProjectsPage;
