import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { Link } from 'gatsby';

interface Props extends React.HTMLProps<HTMLDivElement> {
  project: any;
}

const ProjectCard: React.FC<Props> = ({ project, ...props }) => {
  return (
    <div
      {...props}
      style={{ scrollSnapAlign: 'start', scrollBehavior: 'smooth' }}
      className="flex flex-col h-[350px] w-[210px] min-w-[210px] md:h-[500px] md:w-[300px] md:min-w-[300px] rounded-lg justify-between shadow-stripe my-1 mx-2"
      // onClickCapture={(e) => handleOnItemClick(e, project)}
    >
      <Link to={project.node.slug.current}>
        <div
          style={{ backgroundColor: project.node.cardColor?.hex ?? '#EAFFD0' }}
          className={`flex border-b justify-center items-center min-h-[96px] h-24 md:h-32 rounded-t-lg hover:opacity-70 transition-opacity`}
        >
          {project.node.mainImage ? (
            <Image
              className="object-top object-contain w-full px-10 md:px-16"
              {...(project.node.mainImage ?? '')}
            />
          ) : (
            <h2 className="uppercase font-bold text-white text-lg">
              {project.node.name}
            </h2>
          )}
        </div>
      </Link>
      <div className="flex flex-col px-2 md:px-4 flex-1 border-b overflow-hidden">
        <Link to={project.node.slug.current}>
          <h3 className="text-lg font-bold my-2 md:my-4 link">
            {project.node.name}
          </h3>
        </Link>
        <p className="overflow">{project.node.shortDescription}</p>
      </div>
      {/* TODO: Add a ellipse effect on overflow */}
      <div className="flex flex-wrap px-2 py-1 my-2 md:px-4 md:my-4 min-h-[80px] h-20 md:h-32 overflow-hidden content-start -m-0.5 md:-m-1">
        {project.node.technologies.map((techRef: any, index: number) => (
          <div key={index} className="border rounded px-2 py-1 m-0.5 md:m-1">
            {techRef.technology.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectCard;
